<template>
  <div>
    <card-component
      v-for="(rsession,index) in rsessions"
      :key="index"
      :title="rsession.uuid"
      class="tile is-child"
    >
      {{ rsession.content }}
    </card-component>
  </div>
</template>

<script>
import apiService from '@/services/apiService'

export default {
  name: 'Admin',
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue')
  },
  data () {
    return {
      rsessions: [],
      error: null
    }
  },
  mounted () {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    apiService.getRSessions()
      .then(data => {
        this.rsessions = data
        loadingComponent.close()
      })
      .catch(error => {
        this.error = error
        loadingComponent.close()
      })
  }
}
</script>
